















import { Component, Vue, Prop } from 'vue-property-decorator';
import GeneralNavBar from '@/components/general/layout/GeneralNavBar.vue';
import GeneralSideBar from '@/components/general/layout/GeneralSideBar.vue';
@Component({
  components: {
    GeneralNavBar,
    GeneralSideBar,
  },
})
export default class GeneralPage extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  showUserProfile!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  showSidebar!: boolean;
}
