import { render, staticRenderFns } from "./GeneralSideBar.vue?vue&type=template&id=3c5ae9da&scoped=true&"
import script from "./GeneralSideBar.vue?vue&type=script&lang=ts&"
export * from "./GeneralSideBar.vue?vue&type=script&lang=ts&"
import style0 from "./GeneralSideBar.vue?vue&type=style&index=0&id=3c5ae9da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5ae9da",
  null
  
)

export default component.exports