






















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import { CurrentRoute } from '@/store/general/current-route/index';
@Component({})
export default class GeneralSideBar extends Vue {
  $router!: VueRouter;
  currentRouteStore = CurrentRoute.detail;
  navigateBack() {
    const routeName: string = this.currentRouteStore.returnLink;
    this.$router.push({
      name: routeName,
    });
  }

  get displayBackButton(): boolean {
    if (
      this.currentRouteStore.returnLink === '' ||
      this.currentRouteStore.returnLink.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  }
}
